<template>
  <!-- small view for side drawers -->
  <div v-if="viewType === 'small'" class="box">
    <div>
      <b>
        <ui-base-checkbox
          v-if="!showSuccessAnimation"
          :checkValue="todo.IsDone"
          v-model="todo.IsDone"
          :label="todo.Subject"
          @input="markAsDone"
          :cssClassLabel="{
            'is-strikethrough': todo.IsDone,
            'has-text-grey': todo.IsDone,
          }"
        />
      </b>
      <success-animation :width="20" :height="20" v-if="showSuccessAnimation" />
      <div class="has-text-grey">
        {{ $t('Components.ToDos.ToDoItem.Text_DoBefore')
        }}{{ todo.DoBefore | dateFormat($i18n.locale, 'longDateFormat') }}
      </div>
      <div
        v-if="showMore"
        class="todo-body has-margin-top"
        v-html="$options.filters.nl2br(todo.Body)"
      ></div>
      <div v-if="showMore" class="has-text-grey has-margin-top">
        {{ $t('Components.ToDos.ToDoItem.Text_CreatedBy') }}
        {{ todo.CreatedByName }} -
        {{ todo.CreatedOn | dateFormat($i18n.locale, 'longDateFormat') }}
      </div>
      <div class="level is-small has-margin-top">
        <div v-if="!showMore" class="level-left">
          <a @click="setShowMore">{{
            $t('Components.ToDos.ToDoItem.Icon_ShowMore')
          }}</a>
        </div>
        <div v-if="showMore" class="level-left">
          <a @click="setShowLess">{{
            $t('Components.ToDos.ToDoItem.Icon_ShowLess')
          }}</a>
        </div>
        <div class="field is-grouped level-right">
          <p class="control">
            <a @click="setShowShareToDo">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'share']" />
              </span>
            </a>
          </p>
          <p class="control">
            <a @click="setShowEditToDo">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'edit']" />
              </span>
            </a>
          </p>
          <p class="control">
            <a @click="setShowDeleteToDo">
              <span class="icon is-danger">
                <font-awesome-icon
                  :icon="['fas', 'trash-alt']"
                  class="has-text-danger"
                />
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- normal view -->
  <tr v-else :class="{ 'has-text-grey': todo.IsDone }">
    <td>
      <ui-base-checkbox
        :checkValue="todo.IsDone"
        v-model="todo.IsDone"
        :label="todo.Subject"
        :disabled="isSaving"
        @input="markAsDone"
        :cssClassLabel="[
          {
            'is-strikethrough': todo.IsDone,
            'has-text-grey': todo.IsDone,
          },
          'has-text-weight-bold',
        ]"
      />
    </td>
    <td v-if="viewType !== 'small'">
      <transition name="fade" mode="out-in">
        <div key="done" v-if="todo.IsDone && !isSaving">
          <div>{{ todo.IsDoneByName }}</div>
          <div>
            {{ todo.IsDoneOn | dateFormat($i18n.locale, 'longDateFormat') }}
          </div>
        </div>
        <div v-else>To-do is open</div>
      </transition>
    </td>
    <td v-if="viewType !== 'small'">
      {{ printDoBeforeDate }}
    </td>
    <td v-if="viewType !== 'small'">
      {{ todo.Notifications.length }}
    </td>
    <td v-if="viewType !== 'small'">
      <span>{{ todo.CreatedByName }} </span>
    </td>
    <td v-if="viewType !== 'small'">
      <span
        >{{
          new Date(todo.CreatedOn) | dateFormat($i18n.locale, 'longDateFormat')
        }}
      </span>
    </td>
    <td v-if="viewType !== 'small'">
      <span v-if="showLink && todo.LinkTypeId !== 'None'"
        ><a @click="goToItem()">{{ todo.TypeId }} {{ todo.ItemName }}</a></span
      >
    </td>
    <td>
      <div class="field is-grouped is-grouped-right">
        <p class="control">
          <a @click="setShowShareToDo">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'share']" />
            </span>
          </a>
        </p>
        <p class="control">
          <a @click="setShowEditToDo">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'edit']" />
            </span>
          </a>
        </p>
        <p class="control">
          <a @click="setShowDeleteToDo">
            <span class="icon is-danger">
              <font-awesome-icon
                :icon="['fas', 'trash-alt']"
                class="has-text-danger"
              />
            </span>
          </a>
        </p>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import todoProvider from '@/providers/todo'

const SuccessAnimation = () =>
  import('@/components/UI/Components/SuccessAnimation')

export default {
  components: {
    'success-animation': SuccessAnimation,
  },

  props: {
    todo: {
      default: function () {
        return null
      },
      type: Object,
    },

    showLink: {
      type: Boolean,
      default: false,
    },

    viewType: {
      default: 'normal',
      type: String,
    },
  },

  data() {
    return {
      accountIds: [],
      accounts: [],
      channelId: Number(this.$route.params.channelId),
      currentDate: new Date().getTime(),
      mToDo: this.todo,
      showToDo: true,
      showSuccessAnimation: false,
      showMore: false,
      showLess: true,
      isSaving: false,
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profileStore/getProfile',
    }),

    nrOfNotifications() {
      let total = 0

      if (this.todo) {
        total = this.todo.Notifications.length
      }

      return total
    },

    printDoBeforeDate() {
      return !this.todo.DoBefore
        ? '-'
        : this.$options.filters.dateFormat(
            this.$options.filters.convertTimeStampToIsoDateTimeString(
              this.todo.DoBefore
            ),
            this.$i18n.locale,
            'longDateFormat'
          )
    },

    colleagues() {
      let self = this
      let colleagues = []

      if (self.accounts && self.accounts.length > 0) {
        colleagues = self.accounts.filter(
          (a) => a.ProfileId !== self.profile.Id
        )
      }

      return colleagues
    },
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    markAsDone() {
      let self = this
      if (!this.isSaving) {
        this.isSaving = true
        todoProvider.methods
          .setToDoStatus(self.todo)
          .then((response) => {
            if (response.status === 200) {
              //self.mToDo = response.data
              //this.$emit('statusChangedToDo', self.mToDo)
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    goToItem() {
      switch (this.todo.LinkTypeId) {
        case 'Location':
          this.goToLocation(this.todo.LinkItemId)
          break
        case 'Reservation':
          this.goToReservation(this.todo.LinkItemId)
          break
        case 'Option':
          this.gotToOption(this.todo.LinkItemId)
          break
        case 'Space':
          this.goToSpace(this.todo.LinkItemId)
          break
        case 'Invoice':
          this.goToInvoice(this.todo.LinkItemId)
          break
        case 'Company':
          this.goToCompany(this.todo.LinkItemId)
          break
        case 'Customer':
          this.goToCustomer(this.todo.LinkItemId)
          break
        case 'Voucher':
          this.goToVoucher(this.todo.LinkItemId)
          break
        case 'Event':
          this.goToEvent(this.todo.LinkItemId)
          break
        case 'ProposalRequest':
          this.goToProposalRequest(this.todo.LinkItemId)
          break
      }
    },

    goToCompany(companyId) {
      this.$router.push({
        name: `crm-companies-detail`,
        params: { companyId: companyId },
      })
    },

    goToCustomer(customerId) {},

    goToEvent(eventId) {
      this.$router.push({
        name: `events-list-detail`,
        params: { eventId: eventId },
      })
    },

    goToInvoice(invoiceId) {
      this.$router.push({
        name: `invoices-list-detail`,
        params: { invoiceId: invoiceId },
      })
    },

    goToLocation(locationId) {
      this.setLocation(null)

      this.$router.push({
        name: `locations-detail`,
        params: { channelId: this.channelId, locationId: locationId },
      })
    },

    gotToOption(optionId) {
      this.$router.push({
        name: `options-detail`,
        params: { optionId: optionId },
      })
    },

    goToProposalRequest(requestId) {
      this.$router.push({
        name: `proposals-requests-detail`,
        params: { requestId: requestId },
      })
    },

    goToReservation(reservationId) {
      this.$router.push({
        name: `reservations-list-detail`,
        params: { reservationId: reservationId },
      })
    },

    goToSpace(spaceId) {
      this.$router.push({
        name: `spaces-detail`,
        params: { spaceId: spaceId },
      })
    },

    goToVoucher(voucherId) {
      this.$router.push({
        name: `vouchers-detail`,
        params: { voucherId: voucherId },
      })
    },

    setShowDeleteToDo() {
      this.$emit('deleteToDo', this.todo)
    },

    setShowEditToDo() {
      this.$emit('editToDo', this.todo)
    },

    setShowShareToDo() {
      this.$emit('shareToDo', this.todo)
    },

    setShowMore(todo) {
      this.showMore = true
    },

    setShowLess(todo) {
      this.showMore = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/template';
.tabs {
  margin-bottom: 2px;
}
.todo-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 5px;
  border-top: none;
  max-height: 150px;
}
.animation {
  width: 50px;
  height: 50px;
}
.table {
  td {
    &:first-child {
      min-width: 25%;
    }
  }
}
.is-strikethrough {
  text-decoration: line-through;
}
</style>
